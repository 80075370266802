<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-card class="pt-10">
      <!-- <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-col>
                <v-row>
                  <h3>
                    {{ $t('Profile Information') }}
                  </h3>
                </v-row>
                <v-row>
                  <p>
                    {{ $t('Update your account\'s profile information and email address.') }}
                  </p>
                </v-row>
              </v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="userData.first_name"
                    :label="$t('First Name')"
                    :counter="20"
                    outlined
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="userDataLocal.last_name"
                    :label="$t('Last Name')"
                    :counter="20"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-text-field
                v-model="userDataLocal.email"
                :label="$t('Email')"
                outlined
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-card-text>
            <v-col>
              <v-row>
                <h3>
                  {{ $t('Update Password') }}
                </h3>
              </v-row>
              <v-row>
                <p>
                  {{ $t('Ensure your account is using a long, random password to stay secure.') }}
                </p>
              </v-row>
            </v-col>
            <v-text-field
              v-model="userDataLocal.password"
              :label="$t('Current password')"
              :append-icon="showPassword ? icons.mdiEye :icons.mdiEyeOff "
              :type="showPassword ? 'text' : 'password'"
              :hint="$t('This is your password set at the moment.')"
              value="Enter the current password"
              counter
              outlined
              persistent-hint
              @click:append="showPassword = !showPassword"
            />
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="userDataLocal.newPassword"
              :label="$t('New password')"
              :append-icon="showNewPassword ? icons.mdiEye :icons.mdiEyeOff "
              :type="showNewPassword ? 'text' : 'password'"
              :value="$t('Enter the password to be set')"
              counter
              outlined
              @click:append="showNewPassword = !showNewPassword"
            />
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="userDataLocal.confirmPassword"
              :label="$t('Confirm new password')"
              :append-icon="showConfirmPassword ? icons.mdiEye :icons.mdiEyeOff "
              :type="showConfirmPassword ? 'text' : 'password'"
              :hint="$t('A good password is at least 15 characters or at least 8 characters long, including a number and a lowercase letter.')"
              :value="$t('Enter the password to be set')"
              counter
              outlined
              persistent-hint
              @click:append="showConfirmPassword = !showConfirmPassword"
            />
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              class="mr-0 "
              color="primary"
              type="submit"
              @click="checkPassword"
            >
              <v-icon class="pr-1">
                {{ icons.mdiCheck }}
              </v-icon>
              {{ $t('Save') }}
            </v-btn>
            <v-snackbar
              v-model="snackbar"
              :color="snackbarColor"
              top
            >
              {{ snackbarMessage }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  {{ $t('Close') }}
                </v-btn>
              </template>
            </v-snackbar>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDotsVertical, mdiFilePdf, mdiEye,
  mdiEyeOff,
} from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'

export default {
  components: {},
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  watch: {},
  methods: {
    checkPassword() {
      this.password_length = this.userDataLocal.newPassword.length
      // eslint-disable-next-line no-useless-escape
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (this.password_length > 8) {
        this.contains_eight_characters = true
      } else {
        this.contains_eight_characters = false
      }

      this.contains_number = /\d/.test(this.userDataLocal.newPassword)
      this.contains_uppercase = /[A-Z]/.test(this.userDataLocal.newPassword)
      this.contains_special_character = format.test(this.userDataLocal.newPassword)

      if (
        this.contains_eight_characters === true
        && this.contains_special_character === true
        && this.contains_uppercase === true
        && this.contains_number === true
        && this.userDataLocal.newPassword === this.userDataLocal.confirmPassword
      ) {
        this.valid_password = true
        store.dispatch('app-user/resetPassword', this.userDataLocal).then(() => {
          this.snackbar = true
          this.snackbarColor = 'success'
          this.snackbarMessage = this.successMsg
        }).catch(error => {
          this.snackbarColor = 'error'
          this.snackbarMessage = error.response.data.message
          this.snackbar = true
        })
      } else {
        this.valid_password = false
        this.snackbarColor = 'error'
        this.snackbarMessage = this.snackbarText
        this.snackbar = true
      }
    },
  },
  setup(props) {
    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))

    return {
      icons: {
        mdiEye,
        mdiEyeOff,
        mdiDotsVertical,
        mdiFilePdf,
      },
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: null,
      successMsg: 'Settings Saved',
      errorMsg: 'Something went wrong!',
      snackbarText: 'The password does not match the minimal requirements. Please choose a new one',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      userDataLocal,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
