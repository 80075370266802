<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-card-title>
            <v-avatar
              v-if="!imagePreview"
              v-ripple
              size="150px"
              class="red lighten-3 mb-3 avatar"
              @click="launchFilePicker()"
              @mouseover="show=true"
              @mouseleave="show=false"
            >
              <img :src="getAvatar()" />
            </v-avatar>
            <v-avatar
              v-else
              v-ripple
              size="150px"
              class="mb-3 avatar"
              @click="launchFilePicker()"
            >
              <img
                v-show="showPreview"
                :src="imagePreview"
              >
            </v-avatar>
            <input
              ref="refInputEl"
              type="file"
              accept=".png,.jpg"
              :name="uploadFieldName"
              style="display:none"
              @change="onFileChange"
            >
            <v-icon
              v-if="show"
              class="avatar-icon"
              :is-user-profile="isUserProfile"
            >
              {{ icons.mdiPencil }}
            </v-icon>
            <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->

            <!-- error dialog displays any potential error messages -->
            <v-dialog
              v-model="errorDialog"
              max-width="300"
            >
              <v-card>
                <v-card-text class="subheading">
                  {{ errorText }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    @click="errorDialog = false"
                  >
                    Got it!
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="errorDialog"
              max-width="300"
            >
              <v-card>
                <v-card-text class="subheading">
                  {{ errorText }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    flat
                    @click="errorDialog = false"
                  >
                    Got it!
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>

          <span class="mb-2">{{ userData.first_name }} {{ userData.last_name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            {{ $t('Details') }}
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2"> {{ $t('Email') }}:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2"> {{ $t('First Name') }}:</span>
              <span class="text--secondary">{{ userData.first_name }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2"> {{ $t('Last Name') }}:</span>
              <span class="text--secondary">{{ userData.last_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('Status') }}:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.deleted_at)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(userData.deleted_at)}--text font-weight-medium text-capitalize`"
                >
                  {{ userData.deleted_at ? $t('deleted') : $t('active') }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('Role') }}:</span>
              <span class="text--secondary text-capitalize">{{ (userData.roles || []).map(r =>r.name).join(', ') }}</span>

              <!-- <v-chip
                v-for="r in (userData.roles || [])"
                :key="r.id"
                small
                label
                color="primary"
                class="v-chip-light-bg primary--text font-weight-medium text-capitalize mr-2"
              >
                {{ r.name }}
              </v-chip> -->
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('Groups') }}:</span>
              <span class="text--secondary text-capitalize">{{ (userData.groups || []).map(r =>r.name).join(', ') }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            {{ $t('Edit') }}
          </v-btn>
          <v-btn
            color="error"
            outlined
          >
            {{ $t('Suspended') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from '@axios'
import store from '@/store'
import {
  mdiPencil, mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle,
} from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { onUnmounted, ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'
import userStoreModule, { USER_APP_STORE_MODULE_NAME } from '../userStoreModule'
import useUsersList from '../user-list/useUsersList'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isUserProfile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      baseUrl: process.env.VUE_APP_BASE_URL_FILE,
    }
  },
  watch: {
    imagePreview: {
      handler() {
        this.saved = false
      },
      deep: true,
    },
  },
  methods: {
    launchFilePicker() {
      this.$refs.refInputEl.click()
    },
    getAvatar() {
      if (this.userData && this.userData.avatar) {
        return (this.baseUrl + this.userData.avatar).replace(/([^:]\/)\/+/g, '$1')
      }

      return (`${this.baseUrl}/0/Default_avatar.png`).replace(/([^:]\/)\/+/g, '$1')
    },
    onFileChange(event) {
    /*
    Set the local file variable to what the user has selected.
    */
      // eslint-disable-next-line prefer-destructuring
      this.formFields.picture = event.target.files[0]
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]

      /*
      Initialize a File Reader object
      */

      if (this.formFields.picture) {
        const size = this.formFields.picture.size / this.maxSize / this.maxSize
        if (size > 3) {
          // check whether the size is greater than the size limit
          this.errorDialog = true
          this.errorText = 'Your file is too big! Please select an image under 3MB'
        // eslint-disable-next-line brace-style
        }

        /*
           Fire the readAsDataURL method which will read the file in and
           upon completion fire a 'load' event which we will listen to and
           display the image in the preview.
           */
        else {
          const reader = new FileReader()
          reader.readAsDataURL(this.formFields.picture)

          reader.addEventListener('load', () => {
            this.showPreview = true
            this.imagePreview = reader.result
            const fd = new FormData()
            fd.append('avatar', this.selectedFile, this.selectedFile.name)
            axios
              .post(`/users/${this.$route.params.id}/avatar`, fd, {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              })
          })
        }
      }

      // store.dispatch('app-user/updateAvatar', this.imagePreview )
    },
    savedAvatar() {
      this.saving = false
      this.saved = true
    },
  },
  setup() {
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const { resolveUserStatusVariant, resolveUserRoleVariant } = useUsersList()
    const isBioDialogOpen = ref(false)

    return {
      show: false,
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,
      isBioDialogOpen,
      icons: {
        mdiPencil,
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
      errorDialog: null,
      errorText: '',
      uploadFieldName: 'file',
      maxSize: 3072,
      saving: false,
      saved: false,
      imagePreview: null,
      showPreview: false,
      formFields: {
        title: null,
        description: null,
        picture: null,
      },
    }
  },
}
</script>
<style scoped>
.avatar-icon {
    margin: 10px 0px -70px -50px;
}
.avatar:hover{
  opacity:0.5;
  cursor: pointer;
}
</style>
