<template>
  <div class="app-customizer">
    <v-card class="pt-10">
      <v-row>
        <v-col>
          <v-card-text>
            <v-col>
              <v-row>
                <h3>
                  {{ $t('Theming') }}
                </h3>
              </v-row>
              <v-row>
                <p>
                  {{ $t('Skin') }}
                </p>
              </v-row>
              <v-radio-group
                v-if="isUserProfile"
                v-model="appSkinVariant"
                class="mt-1"
                hide-details
                row
              >
                <v-radio
                  v-for="option in skinOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                />
              </v-radio-group>
              <v-radio-group
                v-if="!isUserProfile"
                v-model="otherUserSkinVariant"
                class="mt-1"
                hide-details
                row
              >
                <v-radio
                  v-for="option in skinOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                />
              </v-radio-group>
            </v-col>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card-text>
            <v-col>
              <v-row>
                <h3>
                  {{ $t('Mode') }}
                </h3>
              </v-row>
              <v-row>
                <p>&nbsp;</p>
              </v-row>
              <div class="d-flex align-center">
                <span class="text--secondary text-sm">Hell</span>
                <v-switch
                  v-if="isUserProfile"
                  v-model="isDark"
                  hide-details
                  class="mt-0 mx-2"
                />
                <v-switch
                  v-if="!isUserProfile"
                  v-model="otherUserIsDark"
                  hide-details
                  class="mt-0 mx-2"
                />
                <span class="text--secondary text-sm">Dunkel</span>
              </div>
            </v-col>
          </v-card-text>
        </v-col>
      </v-row>
      <v-spacer />
      <v-card-actions class="justify-end">
        <v-btn
          class="mr-0 "
          color="primary"
          type="submit"
          @click="updateSetting"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-snackbar
          v-model="snackbar"
          :color="snackbarColor"
          top
        >
          {{ snackbarMessage }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import store from '@/store'
import { getVuetify } from '@core/utils'
import themeVariants from './themeVariants'

// 3rd Party

export default {
  components: {

  },
  props: {
    userData: {
      type: Object,
      required: true,
    },

    isUserProfile: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const isCustomizerOpen = ref(false)
    const snackbar = ref(false)
    const snackbarMessage = ref(null)
    const snackbarColor = ref(null)
    const successMsg = ref('Settings Saved')
    const { userData, isUserProfile } = props
    const { id, settings = {} } = userData || {}
    // eslint-disable-next-line object-curly-newline
    const {
      appContentLayoutNav,
      appSkinVariant,
      isDark,
    } = useAppConfig()
    let bueActiveTheme = 'light'
    let bueSkinVariant = 'default'
    if (settings && settings['bue-active-theme']) {
      bueActiveTheme = settings['bue-active-theme']
    }
    if (settings && settings['bue-skin-variant']) {
      bueSkinVariant = settings['bue-skin-variant']
    }

    if (isUserProfile) {
      $vuetify.theme.dark = bueActiveTheme === 'dark'
      localStorage.setItem('bue-active-theme', bueActiveTheme)
      store.commit('appConfig/UPDATE_APP_SKIN_VARIANT', bueSkinVariant)
      localStorage.setItem('bue-skin-variant', bueSkinVariant)
    }

    const selectedTheme = ref('vuexy')

    const skinOptions = computed(() => {
      const options = [
        { label: 'Standard', value: 'default' },
        { label: 'Bordered', value: 'bordered' },
        { label: 'Halbdunkel', value: 'semi-dark' },
      ]
      if (appContentLayoutNav.value === 'horizontal') options.splice(-1, 1)

      return options
    })
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const otherUserIsDark = ref(bueActiveTheme === 'dark')
    const otherUserSkinVariant = ref(bueSkinVariant)

    const updateSetting = () => {
      const payload = {
        id,
        settings: [
          {
            key: 'bue-skin-variant',
            value: (isUserProfile ? appSkinVariant : otherUserSkinVariant).value,
          },
          {
            key: 'bue-active-theme',
            value: (isUserProfile ? isDark : otherUserIsDark).value ? 'dark' : 'light',
          },
        ],
      }
      store.dispatch('app-user/updateSettings', payload).then(() => {
        snackbar.value = true
        snackbarColor.value = 'success'
        snackbarMessage.value = successMsg.value

        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000)
      })
        .catch(error => {
          snackbar.value = true
          snackbarColor.value = 'error'
          snackbarMessage.value = error.response.data.message
        })
    }

    return {
      updateSetting,
      isCustomizerOpen,
      selectedTheme,
      themeVariants,
      snackbar,
      snackbarMessage,
      snackbarColor,
      successMsg,

      // App Content Layout Nav
      appContentLayoutNav,

      // Skin
      appSkinVariant,
      skinOptions,
      isDark,
      isUserProfile,
      otherUserIsDark,
      otherUserSkinVariant,

      // Perfect scrollbar options
      perfectScrollbarOptions,
      settings,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
