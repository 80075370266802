<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="950px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        {{ $t('Edit User Information') }}
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-row class="ml-1">
                  <h3>
                    {{ $t('Profile Information') }}
                  </h3>
                </v-row>
                <v-row class="ml-1">
                  <p>
                    {{ $t('Update your account\'s profile information and email address.') }}
                  </p>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="userDataLocal.first_name"
                      required
                      :label="$t('First Name')"
                      :counter="20"
                      outlined
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="userDataLocal.last_name"
                      required
                      :label="$t('Last Name')"
                      :counter="20"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-model="userDataLocal.email"
                  :label="$t('Email')"
                  outlined
                  :readonly="$can('manage_user') || $can('update_user_email_address')"
                />
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  class="mr-0"
                  color="primary"
                  type="submit"
                  @click="onSubmit"
                >
                  <v-icon class="pr-1">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  {{ $t('Save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-row class="ml-1">
                  <h3>
                    {{ $t('Password') }}
                  </h3>
                </v-row>
                <v-row class="ml-1">
                  <p>
                    {{ $t('Ensure your account is using a long, random password to stay secure.') }}
                  </p>
                </v-row>
                <v-text-field
                  v-model="userDataLocal.password"
                  class="mt-5"
                  :label="$t('Password')"
                  :append-icon="showPassword ? icons.mdiEye :icons.mdiEyeOff "
                  :type="showPassword ? 'text' : 'password'"
                  hint="At least 8 characters"
                  counter
                  outlined
                  @click:append="showPassword = !showPassword"
                />
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  class="mr-0 "
                  color="primary"
                  type="submit"
                  @click="checkPassword"
                >
                  <v-icon class="pr-1">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  {{ $t('Save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mx-auto">
              <v-card-text>
                <v-row class="ml-1">
                  <h3>
                    {{ $t('Roles') }}
                  </h3>
                </v-row>
                <v-row class="ml-1">
                  <p>
                    {{ $t('A Role defines a set of tasks a user assigned the role is allowed to perform.') }}
                  </p>
                </v-row>
                <v-select
                  v-model="userDataLocal.roles"
                  item-text="title"
                  :label="$t('Roles')"
                  :items="roleOptions"
                  :readonly="$can('manage_user')"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-6"
                  multiple
                  attach
                />
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  class="mr-0"
                  color="primary"
                  type="submit"
                  @click="updateRoles"
                >
                  <v-icon class="pr-1">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  {{ $t('Save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mx-auto">
              <v-card-text>
                <v-row class="ml-1">
                  <h3>
                    {{ $t('Groups') }}
                  </h3>
                </v-row>
                <v-row class="ml-1">
                  <p>
                    {{ $t('A group combines users into an organizational unit.') }}
                  </p>
                </v-row>
                <v-select
                  v-model="userDataLocal.groups"
                  :label="$t('Groups')"
                  :items="groupOptions"
                  item-value="id"
                  item-text="title"
                  multiple
                  attach
                />
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  class="mr-0"
                  color="primary"
                  type="submit"
                  @click="updateGroups"
                >
                  <v-icon class="pr-1">
                    {{ icons.mdiCheck }}
                  </v-icon>
                  {{ $t('Save') }}
                </v-btn>
                <v-snackbar
                  v-model="snackbarShow"
                  :color="snackbarColor"
                  top
                >
                  {{ $t(snackbarMessage) }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="pink"
                      text
                      v-bind="attrs"
                      @click="snackbarShow = false"
                    >
                      {{ $t('Close') }}
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { mdiCheck, mdiEye, mdiEyeOff } from '@mdi/js'
import useUsersList from '../user-list/useUsersList'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {

    // setRoles(value) {
    //   this.roles = value
    // },
    // setGroups(value) {
    //   this.groups = value
    // },
    onSubmit() {
      store.dispatch('app-user/updateUserInfo', this.userDataLocal).then(() => {
        this.successMessage()
        store.dispatch('app-user/fetchUser', this.userData)
      }).catch(error => {
        const emailError = error.response.data.errors.email[0]
        console.log(emailError)
        this.errorMessage(emailError)
      })
    },
    checkPassword() {
      this.password_length = this.userDataLocal.password.length
      // eslint-disable-next-line no-useless-escape
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
      if (this.password_length > 8) {
        this.contains_eight_characters = true
      } else {
        this.contains_eight_characters = false
      }
      this.contains_number = /\d/.test(this.userDataLocal.password)
      this.contains_uppercase = /[A-Z]/.test(this.userDataLocal.password)
      this.contains_special_character = format.test(this.userDataLocal.password)
      if (
        this.contains_eight_characters === true
        && this.contains_special_character === true
        && this.contains_uppercase === true
        && this.contains_number === true
      ) {
        this.valid_password = true
        this.updatePassword()
      } else {
        this.valid_password = false
        this.snackbarColor = 'error'
        this.snackbarMessage = this.snackbarText
        this.snackbarShow = true
      }
    },
    updateRoles() {
      // let asip = this.userDataLocal.roles.map((el) => {
      //   return el.id
      // })
      store.dispatch('app-user/updateUserRoles', this.userDataLocal).then(() => {
        this.successMessage()
        store.dispatch('app-user/fetchUser', this.userData)
      }).catch(error => {
        this.errorMessage(error)
      })
    },
    errorMessage(error) {
      this.snackbarShow = true
      this.snackbarColor = 'error'
      this.snackbarMessage = error
    },
    successMessage() {
      this.snackbarColor = 'success'
      this.snackbarMessage = this.successMsg
      this.snackbarShow = true
    },
    updateGroups() {
      store.dispatch('app-user/updateUserGroups', this.userDataLocal).then(() => {
        this.successMessage()
        store.dispatch('app-user/fetchUser', this.userData)
      }).catch(error => {
        this.errorMessage(error)
      })
    },
    updatePassword() {
      store.dispatch('app-user/updatePassword', this.userDataLocal).then(() => {
        this.successMessage()
        store.dispatch('app-user/fetchUser', this.userData)
      }).catch(error => {
        const passwordError = error.response.data.errors.password[0]
        this.errorMessage(passwordError)
      })
    },
  },

  setup(props) {
    const isBillingAddress = ref(true)

    const userDataLocal = ref({
      first_name: 'test',
    })
    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']
    const { groupOptions, roleOptions } = useUsersList()

    watch(
      () => props.isBioDialogOpen,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      },
    )

    return {
      isBillingAddress,
      statusOptions,
      languageOptions,
      userDataLocal,
      icons: {
        mdiCheck,
        mdiEye,
        mdiEyeOff,
      },
      roles: [],
      roless: [],
      groups: [],
      groupOptions,
      roleOptions,
      showPassword: false,
      firstName: '',
      lastName: '',
      email: '',
      password: null,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      snackbarShow: false,
      snackbarMessage: null,
      snackbarColor: null,
      successMsg: 'Settings-Saved',
      snackbarText: 'The password does not match the minimal requirements. Please choose a new one',

    }
  },
}
</script>
