import store from '@/store'

import { computed } from '@vue/composition-api'

export default function useUserInformation() {
  const user = computed(() => store.getters['app-user/user'])

  // fetch data

  //

  const fetchUser = id => {
    store.dispatch('app-user/fetchUser', { id })
  }

  return {
    user,
    fetchUser,
  }
}
