<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <user-bio-panel
          :user-data="user"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          :is-user-profile="isUserProfile"
        />
      </v-tab-item>

      <v-tab-item v-if="isUserProfile">
        <user-tab-security
          :key="user.id"
          :user-data="user"
        />
      </v-tab-item>

      <v-tab-item>
        <app-customizer
          :user-data="user"
          :is-user-profile="isUserProfile"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import store from '@/store'
import {
  mdiTools,
  mdiLockOpenOutline,
  mdiCogOutline,
} from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import UserBioPanel from './UserBioPanel.vue'
import userStoreModule, { USER_APP_STORE_MODULE_NAME } from '../userStoreModule'
import UserTabSecurity from './UserTabSecurity.vue'
import AppCustomizer from './AppCustomizer.vue'
import useUserInformation from './useUserInformation'

// demos

export default {
  components: {
    UserBioPanel,
    UserTabSecurity,
    AppCustomizer,
  },
  setup() {
    const { router } = useRouter()
    const { user, fetchUser } = useUserInformation()
    const tab = ref('')
    const userData = JSON.parse(localStorage.getItem('userData'))

    // tabs
    const tabs = [
      { title: i18n.tc('Overview'), icon: mdiTools, key: 'overview' },
      { title: i18n.tc('Security'), icon: mdiLockOpenOutline, key: 'security' },
      { title: i18n.tc('Settings'), icon: mdiCogOutline, key: 'settings' },
    ]

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })
    const isPlanUpgradeDialogVisible = ref(false)
    const isUserProfile = ref(parseInt(router.currentRoute.params.id, 10) === userData.id)
    fetchUser(router.currentRoute.params.id)

    return {
      tab,
      tabs: isUserProfile.value ? tabs : tabs.filter(t => t.key !== 'security'),
      icons: {
        mdiTools,
        mdiLockOpenOutline,
        mdiCogOutline,
      },
      user,
      isPlanUpgradeDialogVisible,
      isUserProfile,
    }
  },
}
</script>
